.c-container{
    justify-content: space-between;
}

.c-container>div{
    flex:1;
}
.c-right{
    width:100%;
}
.c-left{
    gap: 0.5rem;
}

.contactModes{
    margin-top:2rem;
    gap:1rem;
}
.contactModes .row{
    gap:1.5rem;
}
.mode{
    width:16rem;
    padding: 1rem;
    border:0.8px solid rgba(128,128,128,0.143);
    border-radius : 5px ;
    gap : 1rem;
    transition: all 300ms ease-in;

}

.mode .button{
    width: 100%;
    background: var(---lightBlue);
    color:var(--blue);
    font-size: 0.9rem;
    font-weight: 600;
}

.mode >:nth-child(1){
    width:100%;
    gap: 1.6rem;

}
.mode .detail .primaryText{
    font-size: 1.1rem;
    font-weight: 600;
}

.mode:hover{
    scale: 1.1;
    box-shadow: var(--shadow);
}
.mode .button:hover{
    background: var(--blue-gradient);
    color: white;
    scale:0.8;
}

@media (max-width: 1024px){
    .c-contaniner{
        flex-direction: column;
    }
    .c-right{
        justify-content: center;
    }
    .contactModes{
        width:100%;
    }
    .row{
        flex-direction: column;
        width:100%
    }
    .mode{
        width: 100%;
    }
}